<template>
	<el-card>
		<advanced-search-bar>
			<template>
				<form-item-sites :abbr="true" :multiple="false" :local="true" v-model="queryForm.website"
					:parent="false" @onload="q.website=queryForm.website;searchEvent()" :clearable="false">
				</form-item-sites>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" @click="addItem">添加配置</el-button>
				</form-item>
			</template>
			<template #advanced>
				<form-item label="广告类型:">
					<el-select v-model="queryForm.type" clearable size="mini">
						<el-option v-for="item in types" :label="item.label" :value="item.value" :key="item.value">
						</el-option>
					</el-select>
				</form-item>
				<form-item label="发布类型:">
					<el-select v-model="queryForm.publishType" clearable size="mini">
						<el-option v-for="item in publishTypes" :label="item.label" :value="item.value" :key="item.value"></el-option>
					</el-select>
				</form-item>
				<form-item label="发布状态:">
					<el-select v-model="queryForm.active" clearable size="mini">
						<el-option v-for="item in actives" :label="item.label" :value="item.value" :key="item.value"></el-option>
					</el-select>
				</form-item>
			</template>
		</advanced-search-bar>
		<divider />
		<el-table height="auto" :data="tableData" size="mini" class="ad-strategy-config-table">
			<el-table-column label="ID" prop="id" min-width="100">
			</el-table-column>
			<el-table-column label="站点" prop="website" min-width="60">
			</el-table-column>
			<el-table-column label="广告类型" min-width="80">
				<template slot-scope="scope">{{scope.row.type|label(types)}}</template>
			</el-table-column>
			<el-table-column label="发布类型" min-width="100">
				<template slot-scope="scope">{{scope.row.publishType|label(publishTypes)}}</template>
			</el-table-column>
			<el-table-column label="计划发布时间" prop="cron" min-width="100">
			</el-table-column>
			<el-table-column label="选款范围" min-width="100">
				<template slot-scope="scope">{{scope.row.selectionRangeTypes|label(selectionRangeTypes)}}</template>
			</el-table-column>
			<el-table-column label="搭配规则" min-width="80">
				<template slot-scope="scope">{{scope.row.groupRule|label(groupRules)}}</template>
			</el-table-column>
			<el-table-column label="搭配组数上限" prop="groupLimit" min-width="120">
				<span slot="header">
					<el-popover
					  placement="top"
					  trigger="hover">
					 <div>
						<small>搭配组数总上限 | 分品类组数上限</small>
					  </div>
					  <i slot="reference" class="el-icon-warning-outline mr-1"></i>
					</el-popover>
					搭配组数上限
				</span>
				<template slot-scope="scope">
					{{scope.row.groupLimit || '不限'}} | {{scope.row.innerGroupLimit || '不限'}}
				</template>
			</el-table-column>
			<el-table-column label="适用范围" min-width="150">
				<template slot-scope="scope">
					<table class="scope-table">
						<tr v-if="scope.row.targetCountries.length>0">
							<td>国家:{{scope.row.targetCountries.join(',')}}</td>
						</tr>
						<tr v-if="scope.row.accountIds">
							<td>账户:{{scope.row.accountIds}}</td>
						</tr>
						<tr v-if="scope.row.campaignIds">
							<td>Campaign:{{scope.row.campaignIds}}</td>
						</tr>
					</table>
				</template>
			</el-table-column>
			<el-table-column label="更新人" prop="updatedBy.name" min-width="100">
			</el-table-column>
			<el-table-column label="更新时间" min-width="150">
				<template slot-scope="scope">
					{{scope.row.updatedAt|formatDate}}
				</template>
			</el-table-column>
			<el-table-column label="状态" min-width="80">
				<template slot-scope="scope">
					<el-switch v-model="scope.row.active" @change="updateStatus(scope.row)">
					</el-switch>
				</template>
			</el-table-column>
			<el-table-column label="操作" min-width="150">
				<template slot-scope="scope">
					<el-button type="text" size="mini" @click="editRow(scope.row)">编辑</el-button>
					<el-button type="text" style="color:black;" size="mini" @click="showLog(scope.row)">查看记录</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination>
		<el-dialog :visible.sync="visible.edit" width="800px" :show-close="false" :before-close="handleClose"
			id="ad-strategy-config-dialog">
			<div slot="title">
				<el-form ref="form" :model="form">
					<el-row :gutter="20">
						<el-col :span="form.type===1?6:8">
							<el-form-item-sites :abbr="true" v-model="form.website" label-width="60px" prop="channel"
								size="mini"></el-form-item-sites>
						</el-col>
						<el-col :span="form.type===1?10:8">
							<el-form-item label="广告类型:" label-width="80px">
								<el-select v-model="form.type" :clearable="false" size="mini" :style="calcWidth">
									<el-option v-for="item in types" :label="item.label" :value="item.value" :key="item.value"></el-option>
								</el-select>
								<el-select v-show="form.type===1" v-model="form.selectionRangeTemplate" :clearable="false" size="mini" class="ml-1" :style="calcWidth" placeholder="请选择模板">
									<el-option v-for="item in templates" :label="item" :value="item" :key="item" :disabled="!availableTempates.includes(item)"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item label="发布类型:" label-width="80px">
								<el-select v-model="form.publishType" :clearable="false" size="mini" class="w-100">
									<el-option v-for="item in publishTypes" :label="item.label" :value="item.value" :key="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<table class="border-top py-3">
				<tr>
					<td width="15%">
						<div class="text-right">发布时间:</div>
					</td>
					<td colspan="3">
						<el-select v-model="form.freq" placeholder="请选择" size="mini" class="mr-1">
							<el-option v-for="item in frequencies" :label="item.label" :value="item.value"
								:key="item.value"></el-option>
						</el-select>
						<el-time-picker v-model="form.time" value-format="HH:mm:ss" arrow-control size="mini" :clearable="false">
						</el-time-picker>
						<div v-if="form.freq == 'week'" class="d-flex flex-row p-1">
							<el-checkbox-group v-model="form.week" class="cron-checkbox">
								<el-checkbox v-for="item in crontabWeek" :label="item.value">{{item.label}}</el-checkbox>
							</el-checkbox-group>
						</div>
					</td>
				</tr>
				<tr>
					<td width="15%">
						<div class="text-right">选款范围:</div>
					</td>
					<td width="35%">
						<div style="width:40%;float:left;" class="mr-1">
							<el-select v-model="form.selectionRangeDays" size="mini" class="mr-1" multiple :multiple-limit="1">
								<el-option v-for="item in selectionRangeDays" :label="item.label" :value="item.value"
									:key="item.value"></el-option>
							</el-select>
						</div>
						<div style="width:calc(60% - 5px);float:left;">
							<el-select v-model="form.selectionRangeTypes" size="mini" multiple>
								<el-option v-for="item in selectionRangeTypes" :label="item.label" :value="item.value"
									:key="item.value" :disabled="selectionRangeTypesDisabled(item)"></el-option>
							</el-select>
						</div>
						<div style="clear:both;"></div>
					</td>
					<td width="15%">
						<div class="text-right">适用国家:</div>
					</td>
					<td width="35%">
						<el-select v-model="form.targetCountries" size="mini" class="w-100" multiple @focus="remoteMethod" :loading="loading" remote filterable>
							<el-option v-for="(item,index) in countries" :label="item.code" :value="item.code" :key="`${item.code}-${index}`">
								<div class="d-flex flex-row align-items-center justify-content-between">
									<div>{{item.code}}</div>
									<div>{{item.name}}</div>
								</div>
							</el-option>
						</el-select>
					</td>
				</tr>
				<tr>
					<td>
						<div class="text-right">搭配规则:</div>
					</td>
					<td>
						<el-select v-model="form.groupRule" size="mini" class="w-100">
							<el-option v-for="item in groupRules" :label="item.label" :value="item.value"
								:key="item.value"></el-option>
						</el-select>
					</td>
					<td>
						<div class="text-right">适用账户:</div>
					</td>
					<td>
						<el-input v-model="form.accountIds" type="textarea" :rows="3" size="mini" class="w-100"></el-input>
					</td>
				</tr>
				<tr>
					<td>
						<div class="text-right mb-2">搭配组数总上限:</div>
						<div class="text-right">分品类组数上限:</div>
					</td>
					<td>
						<div class="mb-1">
							<el-input-number v-model="form.groupLimit" size="mini" :controls="false" :min="0" :step="1"></el-input-number>
							<small style="color:#ccc">(0为不限)</small>
						</div>
						<div>
							<el-input-number v-model="form.innerGroupLimit" size="mini" :controls="false" :min="0" :step="1"></el-input-number>
							<small style="color:#ccc">(0为不限)</small>
						</div>
					</td>
					<td>
						<div class="text-right">适用Campaign:</div>
					</td>
					<td>
						<el-input v-model="form.campaignIds" type="textarea" :rows="3" size="mini" class="w-100"></el-input>
					</td>
				</tr>
				<tr>
					<td colspan="4">
						<div>
							<el-checkbox style="margin-left:45px;" v-model="form.inTurn">同国家&语言&品类&发布类型下campaign/adset依次发布</el-checkbox>
						</div>
					</td>
				</tr>
			</table>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="visible.edit=false">取 消</el-button>
				<el-button type="primary" size="mini" @click="saveAction">确 认</el-button>
			</div>
		</el-dialog>
		<el-drawer id="ad-strategy-logs" :visible.sync="visible.log" direction="rtl" :size="400"
			:before-close="handleClose">
			<logs :item="selectedItem" :key="logsKey"></logs>
		</el-drawer>
	</el-card>
</template>

<script>
	import Common from '@/common/mixins/common.js'
	import {
		mapGetters,
		mapState
	} from 'vuex'
	import Page from '@/common/mixins/page.js'
	import Lodash from 'lodash'
	import Logs from '@/components/ad-strategy/logs.vue'
	import {uuid} from 'vue-uuid'
	const DEFAULT_FORM = {
		website: null,
		type: 0,
		publishType: null,
		cron: "",
		targetCountries: [],
		accountIds: "",
		campaignIds: "",
		selectionRangeDays: [],
		selectionRangeTypes: [],
		selectionRangeTemplate: null,
		groupRule: null,
		groupLimit: 0,
		innerGroupLimit: 0,
		inTurn: false,
		freq: 'day',
		time: '00:00:00',
		week: []
	}
	export default {
		mixins: [Common, Page],
		inject: ['adminLayout'],
		components: {
			Logs
		},
		data() {
			return {
				queryForm: {
					website: '',
					active: null,
					publishType: null,
					type: null
				},
				q: {},
				tableData: [],
				namespace: 'ad-strategy',
				form: {
					...Lodash.cloneDeep(DEFAULT_FORM)
				},
				visible: {
					edit: false,
					log: false
				},
				fetchOnCreated: false,
				logsKey: uuid.v4(),
				selectedItem: null,
				templates: [],
				countries: [],
				loading: false,
				lastAbortController: null
			}
		},
		computed: {
			...mapGetters({
				actives: 'ad-strategy/actives',
				publishTypes: 'ad-strategy/publishTypes',
				types:'ad-strategy/types',
				selectionRangeDays: 'ad-strategy/selectionRangeDays',
				selectionRangeTypes: 'ad-strategy/selectionRangeTypes',
				crontabWeek: 'ad-strategy/crontabWeek',
				frequencies: 'ad-strategy/frequencies',
				groupRules: 'ad-strategy/groupRules',
				availableTempates: 'ad-strategy/availableTempates'
			}),
			calcWidth() {
				if (this.form.type === 1) {
					return {width: 'calc(50% - 5px)'}
				}
				return {width: '100%'}
			}
		},
		filters: {
			label(value, list) {
				try {
					if (Array.isArray(value)) {
						return value.map(v => {
							const res = list.find(k => k.value === v)
							return res && res.label
						}).filter(v => v !== null).join(',')
					}
					return list.find(v => v.value === value).label
				} catch(e) {}
			}
		},
		watch: {
			'visible.edit'(newVal, oldVal) {
				if (newVal && this.templates.length === 0) {
					this.$store.dispatch('fetchProductTemplates')
					.then(templates => {
						this.templates = templates
					})
				}
			},
			'form.website'(newVal, oldVal) {
				if (this.visible.edit) {
					this.form.targetCountries = []
					this.countries = []
					this.lastAbortController && this.lastAbortController.abort()
					this.loading = false
				}
			}
		},
		methods: {
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				const payload = {
					params: {
						...this.q,
						...this.page
					},
					trigger
				}
				return this.$store.dispatch(`${this.namespace}/getList`, payload)
			},
			handleList(content) {
				this.tableData = content.map(v => {
					const accountIds = (JSON.parse(v.accountIds) || []).join(',')
					const campaignIds = (JSON.parse(v.campaignIds) || []).join(',')
					const targetCountries = JSON.parse(v.targetCountries) || []
					const selectionRange = JSON.parse(v.selectionRange) || {}
					const selectionRangeDays = selectionRange.days || []
					const selectionRangeTypes = selectionRange.type || []
					const selectionRangeTemplate = selectionRange.template || null 
					// `${int(second)} ${int(minute)} ${int(hour)} * * ${week}`
					const [second, minute, hour, day, month, week] = v.cron.split(' ')
					const freq = week === '?' ? 'day' : 'week'
					const time = (parseInt(hour) > 9 ? hour : `0${hour}`) + ':' + (parseInt(minute) > 9 ? minute : `0${minute}`) + ':' + (parseInt(second) > 9 ? second : `0${second}`)
					return {
						...v,
						accountIds,
						campaignIds,
						targetCountries,
						selectionRange,
						selectionRangeDays,
						selectionRangeTypes,
						selectionRangeTemplate,
						freq,
						week: freq === 'day' ? [] : week.split(',').map(i => parseInt(i)),
						time
					}
				})
			},
			searchEvent() {
				this.q = {
					...this.queryForm
				}
				this.getList()
			},
			updateStatus(row, index) {
				this.adminLayout.showLoading()
				this.$store.dispatch(`${this.namespace}/activeAdStrategyConfig`, {
						...row
					})
					.then(res => {
						this.adminLayout.hideLoading()
					})
					.catch(err => {
						this.adminLayout.hideLoading()
						this.$showErrMsg(err)
					})
			},
			addItem() {
				this.form = {
					...Lodash.cloneDeep(DEFAULT_FORM),
					website: this.queryForm.website
				}
				this.visible.edit = true
			},
			saveAction() {
				const {
					accountIds,
					campaignIds,
					selectionRangeDays,
					selectionRangeTypes,
					website,
					type,
					publishType,
					targetCountries,
					selectionRangeTemplate
				} = this.form
				if (!website || !publishType) {
					return this.$showErrMsg('当前表单不完整')
				}
				if (targetCountries.length === 0 || selectionRangeDays.length === 0 || selectionRangeTypes.length === 0) {
					return this.$showErrMsg('当前表单不完整')
				}
				if (type === 1 && !selectionRangeTemplate) {
					return this.$showErrMsg('当前表单不完整')
				}
				const selectionRange = {
					days: selectionRangeDays,
					type: selectionRangeTypes,
					template: selectionRangeTemplate
				}
				const accountIds_ = accountIds.match(/\d+/g)
				const campaignIds_ = campaignIds.match(/\d+/g)
				if (!accountIds_ && !campaignIds_) {
					return this.$showErrMsg('请设置一个适用账户或适用Campaign')
				}
				// cron
				const week = this.form.freq == 'week' ? this.form.week.sort().map(v => v + '').join(',') : '?'
				const [hour, minute, second] = this.form.time.split(':')
				const day = week !== '?' ? '?' : '*'
				const cron = `${parseInt(second)} ${parseInt(minute)} ${parseInt(hour)} ${day} * ${week}`
				const body = {
					...this.form,
					accountIds: accountIds_,
					campaignIds: campaignIds_,
					selectionRange,
					cron
				}
				this.adminLayout.showLoading(true)
				this.$store.dispatch(`${this.namespace}/saveAdStrategyConfig`, body)
					.then(res => {
						this.adminLayout.hideLoading()
						this.visible.edit = false
						this.searchEvent()
					})
					.catch(err => {
						this.adminLayout.hideLoading()
					})
			},
			editRow(row) {
				this.form = {
					...Lodash.cloneDeep(DEFAULT_FORM),
					...Lodash.cloneDeep(row),
				}
				this.$nextTick(() => {
					this.visible.edit = true
				})
			},
			showLog(row) {
				this.logsKey = uuid.v4()
				this.selectedItem = row
				this.visible.log = true
			},
			remoteMethod(_) {
				if (this.loading || this.countries.length > 0) return
				this.loading = true
				this.initAbortController()
				const signal = this.lastAbortController && this.lastAbortController.signal
				this.$store.dispatch('fb-contents-country/getSiteAllCountries', this.form.website)
				.then(countries => {
					this.countries = countries
				})
				.finally(() => {
					this.loading = false
				})
			},
			initAbortController() {
				try {
					this.lastAbortController = new AbortController()
				} catch (e) {}
			},
			selectionRangeTypesDisabled({value}) {
				// HOT_SALE, POTENTIAL, NEW_TEST
				if (this.form.selectionRangeTypes.length > 0) {
					const res = this.selectionRangeTypes.find(v => this.form.selectionRangeTypes[0] === v.value)
					if (res) {
						return this.selectionRangeTypes.find(v => value === v.value).group !== res.group
					}
					return true
				} else {
					return false
				}
			}
		}
	}
</script>
<style lang="scss">
	#ad-strategy-config-dialog {
		.el-dialog__body {
			padding-top: 0px;
				
			table {
				border-collapse: separate;
				
				td {
					padding: 5px 5px;
					vertical-align: top;
				}
			}
		}
				
		.cron-checkbox {
			label.el-checkbox {
				width: 50px;
			}
		}	
	}
	

	.el-table__cell {
		table tr {
			background-color: unset;
		}
	}
	.scope-table {
		tr {
			td:nth-child(1) {
				vertical-align: top;
			}
		}
	}
</style>
