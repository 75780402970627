<template>
	<div
		class="ad-strategy-logs"
		v-infinite-scroll="loadData"
		infinite-scroll-disabled="disabled">
		<div v-for="(log, index) in logs" class="ad-strategy-log">
			<div>{{log.createdAt|formatDate('YYYY-MM-DD HH:mm')}} {{log.createdBy.name}} {{log.action}}</div>
			<template v-if="log.body">
				<div class="border-top pt-1 mt-2">变更后内容如下:</div>
				<table width="100%">
					<tr v-for="rule in log.rows">
						<td width="35%" style="vertical-align: baseline;">{{rule.name}}</td>
						<td width="65%">
							<div style="word-break:break-all;">{{rule.value}}</div>
						</td>
					</tr>
				</table>
			</template>
			
		</div>
		<div class="h-100" :class="{'d-none': !loading}" v-loading="loading" element-loading-text="加载中..."></div>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex'
	export default {
		props: {
			item: {
				type: Object,
				default: (() => {})
			}
		},
		data() {
			return {
				loading: false,
				logs: [],
				namespace: 'ad-strategy'
			}
		},
		computed: {
			...mapGetters({
				actives: 'ad-strategy/actives',
				publishTypes: 'ad-strategy/publishTypes',
				types:'ad-strategy/types',
				selectionRangeDays: 'ad-strategy/selectionRangeDays',
				selectionRangeTypes: 'ad-strategy/selectionRangeTypes',
				crontabWeek: 'ad-strategy/crontabWeek',
				frequencies: 'ad-strategy/frequencies',
				groupRules: 'ad-strategy/groupRules'
			}),
		},
		mounted() {
			this.loadData()
		},
		methods: {
			loadData() {
				if (this.loading) return
				this.loading = true
				const { id } = this.item
				this.$store.dispatch(`${this.namespace}/getLogs`, id)
					.then((logs) => {
						this.logs = logs.map(v => {
							const body = JSON.parse(v.newBody)
							console.log(body)
							const rows = []
							if (body) {
								rows.push({
									name: '站点',
									value: body.website
								})
								const adType = this.types.find(v => v.value === body.type)
								rows.push({
									name: '广告类型',
									value: adType && adType.label
								})
								const publishType = this.publishTypes.find(v => v.value === body.publishType)
								rows.push({
									name: '发布类型',
									value: publishType && publishType.label
								})
								rows.push({
									name: '计划发布时间',
									value: body.cron
								})
								const days = body.selectionRange && body.selectionRange.days || []
								rows.push({
									name: '选款范围日期',
									value: days.map(v => this.selectionRangeDays.find(k => k.value === v).label).join(',')
								})
								const types = body.selectionRange && body.selectionRange.type || []
								rows.push({
									name: '选款范围类型',
									value: types.map(v => this.selectionRangeTypes.find(k => k.value === v).label).join(',')
								})
								const groupRule = this.groupRules.find(v => v.value === body.groupRule)
								rows.push({
									name: '搭配规则',
									value: groupRule && groupRule.label
								})
								rows.push({
									name: '搭配组数上限',
									value: body.groupLimit
								})
								rows.push({
									name: '适用国家',
									value: body.targetCountries.join(',')
								})
								rows.push({
									name: '适用账户',
									value: (body.accountIds || []).join(',')
								})
								rows.push({
									name: '适用Campaign',
									value: (body.campaignIds || []).join(',')
								})
								rows.push({
									name: '依次发布',
									value: body.inTurn
								})
							}
							
							return {
								...v,
								body,
								rows
							}
						})
						this.loading = false
					})
					.catch(err => {
						console.error(err)
						this.$showErrMsg(err)
						this.loading = false
					})
			}
		}
	}
</script>

<style lang="scss">
	.ad-strategy-logs {
		padding: 10px;
		overflow-y: auto;
		height: 100%;

		.ad-strategy-log {
			margin-bottom: 20px;
			padding: 15px 10px;
			font-size: 14px;
			border: 1px solid #ddd;
			border-radius: 3px;
			table {
				tr {
					td {
						div {
							word-break:break-all;
						}
					}
				}
			}
		}
	}
</style>
